import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';

/**
 * Header component for DKS
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  /**
   * portal URL for jackrabbit
   */
  portalURL = 'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgID=541265'

  /**
   * Constructor
   * @param authService Authentication service
   */
  constructor(public authService: AuthService) { }

  /**
   * Close Navigation Menu
   */
  handleCloseNav = () => {
    const navCheckBox = document.getElementById('nav-toggle') as HTMLInputElement
    navCheckBox.checked = false
  }

  checkRecitalDate = (): boolean => {
    const now = new Date()
    const startDate = new Date('May 29, 2025')
    const endDate = new Date('Jun 2, 2025')
    return now > startDate && now < endDate
  }
}
