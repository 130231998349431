<header>
  <a
    [routerLink]="['/home']"
    routerLinkActive="side-active-link"
    class="home-link">
    <img
      class="dks-logo"
      src="./../../../../assets/images/logos/DS_Logo_Icon_W.png"
      alt="Dancekids Studio Logo" />
    <span class="no-small">DANCEKIDS STUDIO</span>
  </a>
  <input type="checkbox" id="nav-toggle" class="nav-toggle" />
  <nav>
    <ul>
      <li><a (click)="handleCloseNav()" [routerLink]="['/']">home</a></li>
      <li *ngIf="checkRecitalDate()">
        <a (click)="handleCloseNav()" [routerLink]="['/recital']">Recital</a>
      </li>
      <li>
        <a (click)="handleCloseNav()" [routerLink]="['/classes']">classes</a>
      </li>
      <li>
        <a (click)="handleCloseNav()" [routerLink]="['/gallery']">gallery</a>
      </li>
      <li><a (click)="handleCloseNav()" [routerLink]="['/staff']">staff</a></li>
      <li>
        <a (click)="handleCloseNav()" [routerLink]="['/contact']">contact</a>
      </li>
      <li>
        <a (click)="handleCloseNav()" [href]="portalURL" target="_blank">
          Portal
        </a>
      </li>
      <ng-container *ngIf="authService.user$ | async as user">
        <li *ngIf="user.roles.admin"><a [routerLink]="['/admin']">Admin</a></li>
      </ng-container>
    </ul>
  </nav>
  <label for="nav-toggle" class="nav-toggle-label">
    <span></span>
  </label>
</header>
